a{
  transition: 0.5s;
}
.ham-card:hover{
 background-color: aqua;
 cursor: pointer;
 color: blue;
}

.foot-main-ide{
  display: grid;
  margin-left: 55px;
  grid-template-columns: .5fr .5fr;
  justify-items: start;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.ham-menu{
  z-index: 2;
  width:max-content;
  overflow-x:scroll;
  white-space: nowrap;
  height: auto;
  position: absolute;
  transition: all .2s ease-in;
  display: flex;
  flex-direction: column;
  gap : 19px;
  overflow: hidden;
  text-align: center;
  background: rgba(241, 251, 247, 0.6);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 7px );
  border-radius: 0px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
body::-webkit-scrollbar {
  display: none;
}

.no_shadow{
  box-shadow: none;
}

.login{
  height: 100vh;
  text-align: left;
}
.login-card{
  margin: auto;
  background-color: aqua;
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.login-card > button
{
  width : 50%;
  margin: auto;
  height: 14%;
}

.Hint{
  background-color: aquamarine;
}
.consent{
    /* z-index: 999;
    bottom: 5%;
    top: 2%; */
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  
}

/* Smartphones (landscape) ----------- */
@media only screen 
and (min-width : 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  /* .origin{
    width: auto;
    overflow: hidden;
  } */
}

/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
  .App{
    overflow: hidden;
  }
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
  /* Styles */
}
