.leaderboard{
  background-color:rgb(250, 246, 252);
  display: grid;
  padding: 1rem 12rem;  
}
.leaderboard1{
  background-color:rgb(250, 246, 252);
  display: flex;
  flex-direction: row;

}
.board{
  background-color:rgb(250, 246, 252);
}

h2{
  color: #666;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.text-box{
    border-radius: 15px;
    padding: 24px 60px 32px 40px;
    border: 1px solid #d2d9e7;
}
.high{
  /* background-color:wheat; */
  padding: 5px;
}
.card-pad{
  padding: 32px 60px 24px 40px!important;
  margin-bottom: 2%;
  margin-left: 1px;
  margin-right: 14px;
}
.card-pad1{
  padding: 32px 60px 24px 40px!important;
  margin-bottom: 2%;
  margin-left: 14px;
  margin-right: 22px;

}
.l-section{
  background: #fff!important;
}
.crd-in{
  display: flex;
  font-weight: 700;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.box-c{
  /* max-width: 612px;
  width: 100%;
  margin-right: 16px; */
  display: flex;
  
  margin-left: 15px;
}
.box-c-card{
  width: 50%;
}

.card1{
  background-color: rgb(234, 160, 22);
}
.card-name{
  font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #666;
    margin-top: 32px;
    margin-bottom: 8px;
    margin-bottom: 16px!important;
    margin-top: 0!important;
}
.span{
  color: #fff;
  background-color: red;
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.span1{
  color: #fff;
  background-color: rgb(255, 166, 1);
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.span2{
  color: #fff;
  background-color: rgb(117, 5, 117);
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.span3{
  color: #fff;
  background-color: blue;
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.span4{
  color: #fff;
  background-color: rgb(118, 118, 118);
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.span5{
  color: #fff;
  background-color: #ffbc00;
  padding-left: 10px;
  width: 50px;
  margin-left: 20px;
  margin-right: 4px; 
}

.ne{
  color: black;
  text-decoration: underline;
}

.leader-text{
  display: flex;
  padding: 0rem 1rem;
}
.leader-text-sub{
  width: 50%;
}
.performer{
  color: black;
  display: -ms-flexbox;
  display: flex;
  /* width: 25%; */
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: 20px;
  padding: 5px;
}
.performer1{
  color: black;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  /* text-decoration: underline; */
  /* width: 25%; */
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: 20px;
  padding: 5px;
}
.num-perf{
    font-size: 16px;
    /* line-height: 20px; */
    color: #4a4a4a;
}
.plastic{
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.pt-rank{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
}

.btn{
  font-weight: 700;
  font-size: larger;
  color:  rgb(19, 128, 245);
  background:none;
  border:none;
  margin:0;
  padding:0;
  cursor: pointer;
}

.btn1{
  font-weight: 700;
  background-color: #e4ecff8f;
  color: rgb(27, 58, 161);
  border-radius: 100px;
  border: 0;
  margin-left: 20px;
  width: 45px;
  height: 45px;
  outline-style:initial;       
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .box-c{
    display: flex;
    flex-direction: column;
  }
  .box-c-card{
    width: 100%;
  }
  .leaderboard{
    padding: 1rem 2rem;  
  }
}