.captain {
  height: 60%;
}

.section {
  padding: 2rem 0;
}

.caption {
  /* bottom: 30%; */
  /* display: block !important; */
  background: rgb(2, 0, 36);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  padding-left: 100px;
  padding-right: 100px;
}
.caption-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.caption-icon {
  font-size: 2rem;
}
.caption h2 {
  font-size: 60px;
  letter-spacing: 2px;
  color: #24fbfd;
  padding-top: 5%;
}
.caption h3 {
  font-size: 40px;
  letter-spacing: 2px;
  color: #c49dff;
}
.caption h4 {
  font-size: 40px;
  letter-spacing: 2px;
  color: aliceblue;
}
.imgbanner {
  height: 45vh;
  width: 100%;
}

.swiper {
  width: 70%;
  height: 60%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .caption {
    padding: 1rem;
  }
}
