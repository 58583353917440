.card-container{
    max-width: 210px;
    max-height: 350px;
    margin: 1.5rem;
    transition: transform 0.5s;
}
.card-container:hover{
    transform: scale(1.2);
}

.course-flex{
    display: flex;
    justify-content: space-between;
}

.cardDetails {
    display: flex;
    justify-content: space-between;
}
.flex-course-un{
    background-color:azure;
}
.flex-course-n{
    background-color: azure;
}
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
}
.ribbon__content2 {
    left: -25px;
    top: 30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(41deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    
    background-color: #00DBDE;
    background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
    box-shadow: 0 0px 10px ;
    color: azure;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    outline : 3px solid  #f5cd79;
 }
.ribbon__content {
   left: -25px;
   top: 30px;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(41deg);
   position: absolute;
   display: block;
   width: 225px;
   padding: 10px 0;
   background-image: linear-gradient( 358.4deg,  rgba(249,151,119,1) -2.1%, rgba(98,58,162,1) 90% );
   box-shadow: 0 0px 10px ;
   color: azure;
   text-shadow: 0 1px 1px rgba(0,0,0,.2);
   text-transform: uppercase;
   text-align: center;
   outline : 3px solid  #f5cd79;
}
.ribbon::before {
   top: 0;
   left: 0;
   border-top-color: transparent;
   border-right-color: transparent;
   position: absolute;
   z-index: -1;
   content: '';
   display: block;
   border: 5px solid #f19066;
   box-sizing: content-box;
}
.ribbon::after {
   bottom: 0;
   right: 0;
   border-top-color: transparent;
   border-right-color: transparent;
   position: absolute;
   z-index: -1;
   content: '';
   display: block;
   border: 5px solid #f19066;
}  