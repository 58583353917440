
  a {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    text-decoration: none;
  }
  
  img {
    border: none;
  }

  p{
    margin-bottom: 5px;
  }
  
  *:focus {
    outline: none;
  }
  
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .bg-illustration {
    position: relative;
    height: 100vh;

    width: 1200px;
    background: url("./codenow_cover.png") no-repeat center;
    background-size:cover;

    float: left;
    -webkit-animation: bgslide 2.3s forwards;
            animation: bgslide 2.3s forwards;
  }
  .bg-illustration img {
    width: 248px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: auto;
    margin: 19px 0 0 25px;
  }
  .otp-button {
    align-items: center;
    background-color:azure;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
  }
  .otp-button:hover,
  .otp-button:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  .otp-button:hover {
    transform: translateY(-1px);
    transform: translateX(2px);
  }
  .otp-button:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
 .labels{
    font-weight: 500;
    font-size:x-large;
  }
  @-webkit-keyframes bgslide {
    from {
      left: -100%;
      width: 0;
      opacity: 0;
    }
    to {
      left: 0;
      width: 1194px;
      opacity: 1;
    }
  }
  @keyframes bgslide {
    from {
      left: -100%;
      width: 0;
      opacity: 0;
    }
    to {
      left: 0;
      width: 1194px;
      opacity: 1;
    }
  }
  .login {
    max-height: 100vh;
    overflow-Y:auto;
    float: left;
    margin: 0 auto;
    margin-top: 0%;
    width: calc(100% - 1194px);
  }
  .pri{
    padding-top: 1px;
  }
  .login .container {
    width: 505px;
    margin: 0 auto;
    position: relative;
  }
  .login .container h1 {
    margin-top: 100px;
    font-size: 35px;
    font-weight: bolder;
  }
  .login .container .login-form {
    margin-top: 10%;
  }
  .login .container .login-form .e-form {
    display: -ms-grid;
    display: grid;
  }
  .login .container .login-form .e-form input {
    font-size: 16px;
    font-weight: normal;
    background: rgba(57, 57, 57, 0.07);
    margin: 12.5px 0;
    height: 68px;
    border: none;
    padding: 0 30px;
    border-radius: 10px;
  }
  .login .container .forget-pass {
    position: absolute;
    right: 0;
    margin-top: 189px;
  }
  .login .container .forget-pass a {
    font-size: 16px;
    position: relative;
    font-weight: normal;
    color: #918F8F;
  }
  .login .container .forget-pass a::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 100px;
    background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
    bottom: -4px;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    right: 0;
  }
  .login .container .forget-pass a:hover::after {
    opacity: 1;
  }
  .tc{
    height: 20px;
    width: 20px;
  }
  .privacy{
    display: flex;
    
    /* justify-content:center; */
    /* margin-top: 5px; */
  }
  .policy{
    margin-top: 10px;
    margin-left: 2px;
  }
  .policy p{
    width:80%;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1680px) {
    .bg-illustration {
      width: 50%;
      -webkit-animation: none;
              animation: none;
    }
  
    .login {
      width: 50%;
    }
  }
  @media only screen and (max-width: 1024px) {
    body {
      overflow-x: hidden;
    }
  
    @-webkit-keyframes slideIn {
      from {
        left: -100%;
        opacity: 0;
      }
      to {
        left: 0;
        opacity: 1;
      }
    }
  
    @keyframes slideIn {
      from {
        left: -100%;
        opacity: 0;
      }
      to {
        left: 0;
        opacity: 1;
      }
    }
    .bg-illustration {
      float: none;
      /* background: url("https://i.ibb.co/rwncw7s/bg-mobile.png") center center; */
      background-size: cover;
      -webkit-animation: slideIn 0.8s ease-in-out forwards;
              animation: slideIn 0.8s ease-in-out forwards;
      width: 100%;
      height: 190px;
      text-align: center;
    }
    .bg-illustration img {
      width: 100px;
      height: auto;
      margin: 20px auto !important;
      text-align: center;
    }
    .bg-illustration .burger-btn {
      left: 33px;
      top: 29px;
      display: block;
      position: absolute;
    }
    .bg-illustration .burger-btn span {
      display: block;
      height: 4px;
      margin: 6px;
      background-color: #fff;
    }
    .bg-illustration .burger-btn span:nth-child(1) {
      width: 37px;
    }
    .bg-illustration .burger-btn span:nth-child(2) {
      width: 28px;
    }
    .bg-illustration .burger-btn span:nth-child(3) {
      width: 20px;
    }
  
    .login {
      float: none;
      margin: 0 auto;
      width: 100%;
    }
    .login .container {
      -webkit-animation: slideIn 0.8s ease-in-out forwards;
              animation: slideIn 0.8s ease-in-out forwards;
      width: 85%;
      float: none;
      height: auto;
    }
    .login .container h1 {
      font-size: 25px;
      margin-top: 40px;
    }
    .login .container .login-form {
      margin-top: 90px;
    }
    .login .container .login-form form input {
      height: 45px;
    }
    .login .container .login-form form button[type=submit] {
      height: 45px;
      margin-top: 100px;
    }
    .login .container .login-form .remember-form {
      position: relative;
      margin-top: -14px;
    }
    .login .container .login-form .remember-form span {
      font-size: 16px;
      margin-top: 22px;
      top: inherit;
    }
  
    .forget-pass {
      position: absolute;
      right: inherit;
      left: 0;
      bottom: -40px;
      margin: 0 !important;
    }
    .forget-pass a {
      font-size: 16px;
      position: relative;
      font-weight: normal;
      color: #918F8F;
    }
    .forget-pass a::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      border-radius: 100px;
      background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
      background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
      background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
      bottom: -4px;
      left: 0;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      opacity: 0;
      right: 0;
    }
    .forget-pass a:hover::after {
      opacity: 1;
    }
  }
