.contact-info {
    padding-right: 15px;
  }
  
  .contact-info .sub-title {
    display: block;
    text-transform: uppercase;
    color: #fe4a55;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .contact-info h2 {
    margin-bottom: 12px;
    font-size: 36px;
    font-weight: 800;
  }
  
  .contact-info ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: 35px;
    margin-bottom: 0;
  }
  
  .contact-info ul li {
    position: relative;
    margin-bottom: 35px;
    padding-left: 100px;
  }
  
  .contact-info ul li .icon {
    width: 75px;
    height: 85px;
    background-color: #f8f9f8;
    border-radius: 3px;
    position: absolute;
    text-align: center;
    left: 0;
    font-size: 40px;
    /* color: #fe4a55; */
    -webkit-transition: 0.5s;
    transition: 0.5s;
    top: 0;
  }
  
  .contact-info ul li .icon i {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .contact-info ul li h3 {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 800;
  }
  
  .contact-info ul li p {
    font-weight: 600;
    max-width: 300px;
    margin-bottom: 0;
    margin-top: 2px;
  }
  
  .contact-info ul li:hover .icon {
    /* background-color: #fe4a55; */
    color: #ffffff;
  }
  
  .contact-info ul li:last-child {
    margin-bottom: 0;
  }
  
  .contact-form {
    overflow: hidden;
    padding: 35px;
    margin-left: 15px;
    border-radius: 5px;
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 5%;
  }
  
  .contact-form h2 {
    margin-bottom: 0;
    color:#221638;
    font-size:3rem;
    font-weight: 500;
  }

  .contact-form p {
    color:antiquewhite;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: x-large;
  }
  
  .contact-form form {
    margin-top: 25px;
  }
  
  .contact-form form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form form input,
  .contact-form form textarea {
    display: block;
    width: 100%;
    border: none;
    color:#221638;
    background-color:azure;
    height: 50px;
    padding-left: 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
  }

  .headern{
background-color: #8EC5FC;
background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
padding-bottom: 1%;
  }
  
  .contact-form form input::-webkit-input-placeholder,
  .contact-form form textarea::-webkit-input-placeholder {
    color:#221638;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .contact-form form input:-ms-input-placeholder,
  .contact-form form textarea:-ms-input-placeholder {
    color:#221638;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .contact-form form input::-ms-input-placeholder,
  .contact-form form textarea::-ms-input-placeholder {
    color:#221638;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .contact-form form input::placeholder,
  .contact-form form textarea::placeholder {
    color:#221638;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .contact-form form input:focus::-webkit-input-placeholder,
  .contact-form form textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }
  
  .contact-form form input:focus:-ms-input-placeholder,
  .contact-form form textarea:focus:-ms-input-placeholder {
    color: transparent;
  }
  
  .contact-form form input:focus::-ms-input-placeholder,
  .contact-form form textarea:focus::-ms-input-placeholder {
    color: transparent;
  }
  
  .contact-form form input:focus::placeholder,
  .contact-form form textarea:focus::placeholder {
    color: transparent;
  }
  
  .contact-form form textarea {
    height: auto !important;
    padding-top: 15px;
  }
  
  .contact-form form .default-btn {
    background-color:bisque;
    margin-top: 5px;
    padding: 12px 30px;
    border-radius: 15px;
  }
  
  .contact-form form .default-btn span {
    background-color: #ffffff;
  }
  
  .contact-form form .default-btn:hover {
    color:#221638;
  }
  
  .contact-form form .invalid-feedback {
    color: #ffffff;
  }

  .container{
    border-radius: 15px;
  }
  
  #map iframe {
    height: 500px;
    margin-bottom: -7px;
    width: 100%;
    border: none;
  }