.react-chatbot-kit-chat-container {
    width: 100%;
    height: 100vh;
}

.react-chatbot-kit-chat-message-container {
    height: calc(100vh - 100px);
}

.react-chatbot-kit-chat-bot-message {
    margin-left: unset;
    width: auto;
    max-width: 70%;
}

.react-chatbot-kit-user-chat-message {
    margin-right: unset;
    width: auto;
    max-width: 70%;
}
