.header{
    display: flex;
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% );
    animation: gradient 15s ease infinite;
}
.head-text{
    padding-left: 20px;
}
.text{
    font-size:x-large;
    
    color: darkslategray;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
       
    }
    100%{
      background-position: 0% 50%;
    }
  }