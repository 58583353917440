.editor{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 100px;
    align-items: center;
}
.heading h1{
    font-weight: 900;
}
.codingBg {
    left: 10%;
    position: relative;
}

.editor--header{
    display: flex;
    align-items: center;
}
.editor--embed{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
}
@media only screen and (max-width: 768px) {
    .heading h1{
        margin-top: 170px;
        font-size: 3rem;
    }
    .codingBg{
        left: 0%;
        z-index: -1;
        opacity: 0.4;
    }
    .codeIt{
        max-width: 100%;
        max-height: 40%;
        margin-top: -250px;
    }
}
.nav-item.my-0.mx-4{
    font-size: 15px;
    font-style: oblique;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: rgba(19, 212, 170, 0.379);
    width: 250px;
    height: 30px;
    border-radius: 7px;
    color: rgb(64, 73, 81);
    text-align: center;
}
