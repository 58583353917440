.big-container{
    background: rgb(16,131,98);
    background: radial-gradient(circle, rgba(16,131,98,1) 39%, rgba(69,45,189,1) 100%);
}
.box{
    border:none;
    font-size: large;
    height:60px;
    font-weight: bolder;
    margin-top: 12px;
    margin-left: 10px;
    padding-top: 10px;
    color:azure;
    font-family: 'Inter', sans-serif;
}
.box-head{
    display: flex;
}
.course-cards{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 16rem;
    justify-content: center;
    align-items: center;
}
.diff-heading{
    display: flex;
    align-items: center;
}
.difficulty {
    padding: 20px;
}
.diff-title{
    margin-top: 200px;
    font-size: 60px;
    font-weight: 800;
    color: #221638;
}
.dev {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    left: 12%;
    bottom: -1;
    z-index: -1;
    position: absolute;
}
.heading{
    margin-left: 1%;
    font-size:3rem;
    position: relative;
    font-weight: bolder;
    color:azure;
    font-family: 'Inter', sans-serif;
}
.loader {
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}
.loader .spinner-border {
    position: absolute;
    top: 40%;
    left: 50%;
}
.main{
    padding-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.nav-item-head{
    font-size: 15px;
    font-style:normal;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color:rgba(19, 212, 170, 0.379);;
    width: 250px;
    height: 30px;
    border-radius: 7px;
    color:antiquewhite;
    text-align: center;
}
.two-side{
    width: 50%;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  .heading{
    margin-top: 20%;
    font-size:xx-large;
    position: relative;
    font-weight: bolder;
    color:azure;
    font-family: 'Inter', sans-serif;
  }
  .course-cards{
    margin: 10px;
  }
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .heading{
    font-size:xx-large;
    position: relative;
    font-weight: bolder;
    color:azure;
    font-family: 'Inter', sans-serif;
  }
  .big-container{
    width: 144%;
  }
}

