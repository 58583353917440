.nav{
    display: flex;
    position: relative;
    background-color: white;
    justify-content: center;
    padding-top: 3px;
    padding-bottom: 0px;
}
.nav-contain{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}
.nav.stickyNav {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.navbar {
    display: flex;
    align-items: center;
    position: inherit;
    padding: 0;
    justify-content: space-between; 
    width: 100%;
}

.head-text{
    margin-left: 1px;
    margin-top: 3px;
}
.nav-menu-btn{
    display: none;
    cursor: pointer;
    padding-right: 20px;
}
.nav-options{
    display: flex;
    margin-left: 10%;
    margin: auto;
    width: 100%;
    justify-content: space-between;
}
.nav-options-sub{
  display: flex;
  width: 100%;
  justify-content: center;
}
.nav-options navn.active{
    text-decoration: dashed;
    font-weight: bolder;
    font-size: 120px;
}
.nav-options.hover{
    background-color:cadetblue;
    opacity: 0.5;
}
.nav-item{
    color:blueviolet;
    font-size: 16.5px;
    padding: 5px 0;
    margin-left: 13px;
    margin-right: 13px;
    font-family: 'Inter', sans-serif;
    border:none;
}
.hamburg{
    display: flex;
    margin-left:auto;
}
.nav-item:hover{
    font-weight: bolder;
}

@media only screen and (max-width: 768px) {
    .nav-options{
      display: none;
      
    }
    .nav{
        margin-bottom: 10%;
    }
    .nav-menu-btn{
      display: block;
      transform: translateX(-100%);
    }
    .head-img{
      width: 50%;
    }
}
.nav-options>span{
   padding-inline: 10px;
} 
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  .nav{
    display: flex;
    background-attachment: fixed;
    height: 50%;
  }
  .heading{
    margin-top: 0px;
  }
}
/* @media only screen 
and (min-width : 1224px) {
  .nav{
    width: 100%;
  }
  .nav-contain{
    width: 50%;
    margin-left: 5%;
  }
} */

  