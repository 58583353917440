@media only screen and (min-width: 576px) {
  .col-sm-6,
  .col-sm-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .col-sm-6,
  .col-sm-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .footer-area {
    width: 144%;
  }
  .footer-container {
    max-width: 720px;
  }
  .col-md-6,
  .col-md-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-md-6,
  .col-md-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-md-6,
  .col-md-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .footer-container {
    max-width: 960px;
  }
  .col-lg-6,
  .col-lg-7 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-lg-4,
  .col-lg-5 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-2,
  .col-lg-3 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
}

@media only screen and (min-width: 1200px) {
  .footer-container {
    max-width: 1140px;
  }

  .footer-area {
    width: 144%;
  }
}

@media only screen and (min-width: 1224px) {
  .footer-area {
    width: 100%;
  }
}

.footer-area {
  background-color: #100f1f;
  position: relative;
  z-index: 1;
  padding-top: 100px;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 2rem;
}

.footer-container {
  max-width: 1230px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget h3 {
  margin-bottom: 20px;
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
}

p {
  margin-top: 0;
  color: #606060;
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 16px;
}

.single-footer-widget p {
  color: #ededed;
}

.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.single-footer-widget .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: #1e1d2f;
  font-size: 18px;
  position: relative;
  border-radius: 2px;
}

.single-footer-widget .social-link li a:hover {
  background-color: #fe4a55;
}

.d-block {
  display: block !important;
}
a,
a:hover {
  text-decoration: none;
}

.single-footer-widget .footer-links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .footer-links-list li {
  margin-bottom: 12px;
  color: #ededed;
}
.single-footer-widget .footer-links-list li a {
  color: #ededed;
  display: inline-block;
}

.single-footer-widget .footer-links-list li a:hover {
  color: #fe4a55;
}

.single-footer-widget .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .footer-contact-info li {
  margin-bottom: 13px;
  color: #ededed;
  position: relative;
}
.single-footer-widget .footer-contact-info li a {
  color: #ededed;
  display: inline-block;
}
.single-footer-widget .footer-contact-info li a:hover {
  color: #fe4a55;
}

.footer-bottom-area {
  margin-top: 70px;
  border-top: 2px solid #181726;
  padding-top: 25px;
  padding-bottom: 25px;
}

.align-items-center {
  -webkit-align-items: center !important;
  align-items: center !important;
}

.footer-bottom-area p {
  color: #ededed;
}

p:last-child {
  margin-bottom: 0;
}

.footer-bottom-area ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area ul li {
  color: #ededed;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area ul li a {
  display: block;
  color: #ededed;
}
.footer-bottom-area ul li a:hover {
  color: #fe4a55;
}

.footer-bottom-area ul li:last-child {
  margin-right: 0;
}
