.IdePage{
    overflow-x: hidden;
    background: rgb(16,131,98);
    background: radial-gradient(circle, rgba(16,131,98,1) 39%, rgba(69,45,189,1) 100%);
}
.ide-page{
    display: flex;
}
.tab_btn_cont{
    display: flex;
    
}
.col{
   
}
.buttons {
    background-color:cornsilk;
    padding-top: 1px;
    border: none;
    border-radius: 20px;
    height: max-content;
  }
  .buttons:hover {
    box-shadow: 0 0 0 4px rgb(216, 207, 172);
    transition: 0.7s;
}
.previous{
    color: white;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
}
.copy-text{
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
}
.c-text{
    margin-top: 20px;
    color:#263238;
    margin-bottom: 10%;
}
.copy-button{
    margin-top: 5px;
    margin-right: 15px;
    height:60px;
    width: 60px;
    background-color: azure;
    border: none;
    border-radius:15px;
}
.copy-button:hover{
    background-color: rgb(193, 235, 235);
}
.instruct-buttons{
    background-color: cornsilk;
    border: none;
    border-radius: 15px;
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight:lighter;
    height: 30px;
}
.instruct-buttons:hover{
    box-shadow: 0 0 0 4px rgb(216, 207, 172);
    transition: 0.7s;
}
.tab_btn{
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 800;
    font-size: 2rem;
    border-bottom: 1px solid rgb(182, 171, 171);
    color:cornsilk;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab_btn svg {
    margin-left: 4px;
    position: relative;
    top: 0.15rem;
}
.container{
    padding-top: 1px;
    width: 60%;
    margin-bottom: 34%;
}
.ide-main{
    margin-left: 45px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: .33fr .66fr;
    margin-bottom: 5%;
}

.page{
    width: 100%;
}

.Prob{
    width: 95%;
    text-align: left;
    font-size: 2rem;
    height: 250px;
    margin-left: 25px;
    margin-bottom: 35%;
}
.container-ide{
    display: flex;
    margin-top: 5%;
    margin-right: 5%;
    justify-content: space-between;
}
.about{
    margin-bottom: 20px;
    padding: 0.2rem;
    padding-top: 0;
    color:cornsilk;
}
.probTitle{
    width: 100%;
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(182, 171, 171);
}
.probDescription{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 1em;
}
.inpOut {
    font-weight: bolder;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.inpOutText {
    font-weight: 400;
    background: #f7f9fa;
    padding: 10px 15px;
    color: #263238;
    line-height: 1.6;
    font-size: 15px;
    border-radius: 3px;
}
.hidden {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    position: relative;
    
}
.hiddenSol{
    background-color: #dae5ea;
    filter: blur(6px);
    width: 100%;
    height: 30vh;
    z-index: 1;
}
.hideBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.hidden .hideBox .view-solution{
    border-radius: 7px;
    color:rgb(37, 37, 37);
    padding: 0.5rem 1rem;
    background-color: rgba(125, 132, 136, 0.3);
    font-weight: 600;
    letter-spacing: 2px;
    border: none;
    font-size: 0.8rem;
}
.hidden .hideBox .view-solution:hover{
    background-color: rgba(125, 132, 136, 0.5);
    color: rgba(0, 0, 0, 1);
}
.short{
    margin-top: 0%;
    
}
.question-box{
    
}
@media only screen and (max-width:725px){
    .container-ide{
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        margin-right:5%;
    }
    .question-box{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
        width: 30%;
    }
    .container{
        padding-top: 1px;
        width: 60%;
        margin-bottom: 33%;
    }
}
@media only screen and (max-width: 900px) {
    .ide-main{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    }
    .container-ide{
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        margin-right:25%;
    }
    .question-box{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
        width: 30%;
    }
    .container{
        padding-top: 1px;
        width: 60%;
        margin-bottom: 33%;
    }
}